<template>
  <div>
    <siteHeader />

    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow">
            <div class="card-header bg-transparent">
              <h3 class="mb-0">Receipt</h3>
            </div>
            <div class="card-body">
              <card shadow type="secondary">
                <div class="row1">
                  <form>
                    <div class="pl-lg-4">
                      <div class="row">
                        <div class="col-md-12">
                          <base-input label="Is Member">
                            <!-- <el-select
                              style="width: 100%"
                              v-model="model.is_member"
                              placeholder="Is Member"
                              @change="onMemberChange(model.is_member)"
                            >
                              <el-option
                                v-for="option in yesnoList"
                                :value="option.value"
                                :label="option.label"
                                :key="option.label"
                              >
                              </el-option>
                            </el-select> -->

                            <div>
                              <input
                                @change="onMemberChange(model.is_member)"
                                name="is_member"
                                type="radio"
                                v-model="model.is_member"
                                value="Yes"
                                class="mr-2"
                              />

                              <label class="mr-2">Yes </label>

                              <input
                                @change="onMemberChange(model.is_member)"
                                name="is_member"
                                type="radio"
                                v-model="model.is_member"
                                value="No"
                                class="mr-2"
                              />

                              <label class="mr-2">No </label>
                            </div>
                          </base-input>

                          <div
                            class="error mb-2"
                            v-if="v$.model.is_member.$error"
                          >
                            Field is required.
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Number"
                            input-classes="form-control-alternative"
                            :value="model.number"
                            @input="
                              (event) => (model.number = event.target.value)
                            "
                          />
                          <div class="error mb-2" v-if="v$.model.number.$error">
                            Valid number required.
                          </div>
                        </div>

                        <div class="col-lg-6">
                          <base-input label="Date">
                            <flat-picker
                              @on-open="focus"
                              @on-close="blur"
                              :config="config"
                              class="form-control datepicker"
                              v-model="model.date"
                            >
                            </flat-picker>
                          </base-input>
                          <div class="error mb-2" v-if="v$.model.date.$error">
                            Field is required.
                          </div>
                        </div>
                        <div class="col-lg-6" v-if="ifYes">
                          <base-input label="Member ID">
                            <el-select
                              style="width: 100%"
                              placeholder=""
                              v-model="model.member_id"
                              :value="model.member_id"
                              filterable
                              @change="onChange(model.member_id)"
                            >
                              <el-option
                                v-for="option in memberData"
                                :value="option.id"
                                :label="option.full_name"
                                :key="option.id"
                              >
                              </el-option>
                            </el-select>
                          </base-input>
                          <!-- <div
                            class="error mb-2"
                            v-if="v$.model.member_id.$error"
                          >
                            Field is required.
                          </div> -->
                        </div>
                        <div class="col-lg-6" v-if="!ifYes">
                          <base-input
                            alternative=""
                            label="Name"
                            input-classes="form-control-alternative"
                            :value="model.name"
                            @input="
                              (event) => (model.name = event.target.value)
                            "
                          />
                          <!-- <div class="error mb-2" v-if="v$.model.name.$error">
                            Field is required.
                          </div> -->
                        </div>
                        <div class="col-lg-6">
                          <base-input
                            alternative=""
                            label="Amount($)"
                            input-classes="form-control-alternative"
                            v-bind:readonly="isReadOnly"
                            :value="model.amount"
                            @input="
                              (event) => (model.amount = event.target.value)
                            "
                          />
                          <div class="error mb-2" v-if="v$.model.amount.$error">
                            Valid Amount required.
                          </div>
                        </div>
                        <div class="col-lg-12" v-if="!ifYes">
                          <base-input
                            input-classes="form-control-alternative"
                            label="Address"
                            :value="model.address"
                          >
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              placeholder="Address"
                              v-model="model.address"
                            ></textarea>
                          </base-input>
                        </div>
                      </div>
                      <div class="table-responsive" v-if="ifYes">
                        <h3>Select Payment</h3>
                        <base-table
                          class="table align-items-center table-flush"
                          :class="type === 'dark' ? 'table-dark' : ''"
                          :thead-classes="
                            type === 'dark' ? 'thead-dark' : 'thead-light'
                          "
                          tbody-classes="list"
                          :data="recieptViewData"
                        >
                          <template v-slot:columns>
                            <th>X</th>
                            <th>Member Name</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Paid</th>
                            <th>Notes</th>
                          </template>

                          <template v-slot:default="row">
                            <td>
                              <input
                                type="radio"
                                v-model="profileImg"
                                name="profileImg"
                                :value="row.item"
                                :checked="row.item"
                                @change="paymentSelect(row.item)"
                              />
                            </td>
                            <td class="budget">
                              <div v-for="item in memberData" :key="item.id">
                                <span v-if="item.id === row.item.member_name">
                                  {{ item.name }}
                                </span>
                              </div>
                            </td>
                            <td class="budget">
                              {{ row.item.date }}
                            </td>
                            <td class="budget">
                              {{ row.item.amount }}
                            </td>
                            <td class="budget">
                              {{ row.item.paid }}
                            </td>

                            <td class="budget">
                              {{ row.item.note }}
                            </td>
                          </template>
                        </base-table>
                      </div>
                      <br />
                      <div>
                        <base-input
                          input-classes="form-control-alternative"
                          label="Comment"
                          :value="model.comment"
                        >
                          <textarea
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Comment"
                            v-model="model.comment"
                          ></textarea>
                        </base-input>
                      </div>
                    </div>

                    <br />
                    <div class="row" v-if="id.data == 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="create()"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="id.data != 'undefined'">
                      <div class="col-md-5"></div>
                      <div class="col-md-4">
                        <button
                          type="button"
                          class="btn btn-default"
                          v-on:click="Update()"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <br />
                  <div v-if="isLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Created
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                  <div v-if="isUpdateLoading">
                    <base-alert type="success" dismissible>
                      <span class="alert-inner--text"
                        ><strong>Success!</strong> Record Updated
                        Successfully</span
                      >
                      <button
                        type="button"
                        class="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </base-alert>
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { RepositoryFactory } from "../../repositories/RepositoryFactory";
const recieptRepository = RepositoryFactory.get("reciept");
const memberRepository = RepositoryFactory.get("member");
const paymentRepository = RepositoryFactory.get("payment");
import { ElSelect, ElOption } from "element-plus";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
export default {
  name: "Receipt",
  components: {
    flatPicker,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
  },
  validations() {
    return {
      model: {
        // member_id: { required },
        // name: { required },
        is_member: { required },
        date: { required },
        number: { required, numeric },
        amount: { numeric },
      },
    };
  },
  data() {
    const date = new Date().toJSON().slice(0, 19).replace("T", " ");

    return {
      config: {
        altFormat: "d/m/Y",
        altInput: true,
      },
      v$: useVuelidate(),
      id: {},
      paymentData: {},
      model: {
        is_member: "No",
        date: date,
      },
      isLoading: false,
      isUpdateLoading: false,
      isReadOnly: false,
      recieptViewData: [],
      ifYes: false,
      memberData: [],
      yesnoList: [
        {
          value: "Yes",
          label: "Yes",
        },
        {
          value: "No",
          label: "No",
        },
      ],
    };
  },
  methods: {
    async create() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        return;
      }
      this.model.paymentdata = this.paymentData;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await recieptRepository.create(data);
      if (response.data.status == 100) {
        this.isLoading = true;
        setTimeout(() => {
          this.isLoading = false;
          this.$router.push("/reciept/view_reciept");
        }, 1000);
      }
    },
    async Update() {
      delete this.paymentData.is_delete;
      this.model.paymentdata = this.paymentData;
      let data = RepositoryFactory.JSON_to_URLEncoded(this.model, "data", []);
      let response = await recieptRepository.create(data);
      if (response.data.status == 101) {
        this.isUpdateLoading = true;
        setTimeout(() => {
          this.isUpdateLoading = false;
          this.$router.push("/reciept/view_reciept");
        }, 1000);
      }
    },

    onMemberChange(value) {
      if (value === "Yes") {
        this.isReadOnly = true;
        this.ifYes = true;
      } else {
        this.isReadOnly = false;
        this.ifYes = false;
      }
    },
    onChange(value) {
      for (let index = 0; index < this.memberData.length; index++) {
        const element = this.memberData[index];
        if (element.id == value) {
          this.model.address = element.address;
        }
      }

      paymentRepository.getPaymentBymemid(value).then((data) => {
        if (data.data.length != 0) {
          this.recieptViewData = data.data;
        }
      });
    },
    getList() {
      memberRepository.getMember().then((data) => {
        this.tableData = Object.freeze(data);
        this.memberData = this.tableData.data;
      });
    },
    paymentSelect(value) {
      this.paymentData = value;
      this.model.amount = value.amount;
    },
    async loadNumber() {
      let response = await recieptRepository.getNumber();
      this.model.number = response.data.number;
    },
  },
  async mounted() {
    await this.getList();
    this.id = this.$route.params;
    if (this.id.data != "undefined") {
      await recieptRepository.getReciept(this.id.data).then(async (data) => {
        if (data.data.length != 0) {
          this.model = data.data[0];

          this.paymentData = data.data[0].paymentdata;
          await paymentRepository
            .getPaymentBymemid(this.model.member_id)
            .then((data) => {
              if (data.data.length != 0) {
                this.recieptViewData = data.data;
              }
            });
          if (this.model.is_member === "Yes") {
            this.isReadOnly = true;
            this.ifYes = true;
          } else {
            this.isReadOnly = false;
            this.ifYes = false;
          }
        }
      });
    } else {
      await this.loadNumber();
    }
  },
};
</script>

<style></style>
